import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Button,
  TextField,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Autocomplete,
  Paper,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {capitalCase} from 'change-case'
import onboard_api from 'libs/endpoints/onboard'

import {useSnackbar} from 'notistack'
import axios from 'axios'

// icons
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RemoveCircleOutlineIcon from '@mui/icons-material/CloseOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {styled} from '@mui/material/styles'
const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root.Mui-disabled': {
    // backgroundColor: 'lightgray',
    color: 'red'
  }
})

import {endShift_calculations} from 'utils/gigComputation'
import {drop, pick} from 'lodash'

const EndShiftNotificationRider = ({open, gig, onCommit, handleClose, loading, branches, pickups}) => {
  const {fees, ridersFee, dropoffList} = gig
  // const [workTime, setWorkTime] = useState(gig?.hours)
  const [workTime, setWorkTime] = useState()
  const [late, setLate] = useState('')
  const [extension, setExtension] = useState('')
  const [nightSurge, setNightSurge] = useState('')
  const [checked, setChecked] = useState(false)

  const [rate, setRate] = useState(undefined)
  const [isFinal, setIsFinal] = useState(false)
  const [limit, setLimit] = useState(0)
  const [arrBranches, setBranches] = useState([])
  const [arrPickups, setPickups] = useState([])
  const [dropBranches, setDropBranches] = useState([])
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setLoading] = useState(false)
  const [isScrnLoading, setScrnLoading] = useState(false)

  const [Name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [selectedAddress, setSelectedAddress] = useState({})
  const [isEditingPickup, setIsEditingPickup] = useState(false)
  const [editIndex, setEditIndex] = useState(0)

  const [dropoffDetails, setDropoffDetails] = useState([
    {timeArrived: '', timeFinnished: '', totalTime: '', address: '', timeStamp: ''}
  ])
  const [pickupDetails, setPickupDetails] = useState([
    {timeArrived: '', timeFinnished: '', totalTime: '', address: '', timeStamp: ''}
  ])
  const [openDialog, setOpenDialog] = useState(false)
  const [addressDetails, setAddressDetails] = useState(null)

  const [timeArrived, setTimeArrived] = useState('')
  const [timeFinnished, setTimeFinnished] = useState('')
  const [totalTime, setTotalTime] = useState('')
  const [timeStamp, setTimeStamp] = useState('')
  const [deliveryProof, setDeliveryProof] = useState([])
  const [address, setAddress] = useState('')
  const [isReturnToPickup, setIsReturnToPickup] = useState(false)
  const [totalWaitTime, setTotalWaitTime] = useState(0)
  const [updatedRidersFee, setUpdatedRidersFee] = useState({})
  const [jobsterNotes, setJobsterNotes] = useState('')
  const [paymentDetails, setPaymentDetails] = useState('')
  const [paymentOptions, setPaymentOptions] = useState([])
  // Handle "Return to Pickup" checkbox change
  const handleReturnToPickup = async (event) => {
    setIsReturnToPickup(event.target.checked)

    if (event.target.checked && gig.pickup) {
      const returnToPickupDetail = {
        timeArrived: '',
        timeFinished: '',
        totalTime: '',
        address: {
          label: gig.pickup.address,
          value: gig.pickup.address,
          lat: gig.pickup.lat,
          long: gig.pickup.long
        },
        timeStamp: new Date().toISOString()
      }

      // Use callback to ensure latest state
      setDropoffDetails((prevDetails) => {
        const updatedDetails = [...prevDetails, returnToPickupDetail]
        return updatedDetails
      })
    } else {
      // Use callback for filtering as well
      setDropoffDetails((prevDetails) => {
        const filteredDetails = prevDetails.filter((detail) => detail.address.value !== gig.pickup.address)
        return filteredDetails
      })
    }
  }

  const handlePaymentDetails = (e) => {
    const selectedValue = e.target.value

    const selectedName =
      selectedValue === paymentOptions?.acccountPaymentNumber
        ? paymentOptions?.acccountPaymentName
        : selectedValue === paymentOptions?.altAcctPaymentNumber
        ? paymentOptions?.altAcctPaymentName
        : ''

    const selectedType = paymentOptions?.accountPaymentType

    setPaymentDetails({type: paymentOptions?.accountPaymentType, value: selectedValue, name: selectedName})
  }
  // UseEffect to calculate the distance whenever dropoffDetails change
  useEffect(async () => {
    if (gig.pickup && dropoffDetails.length > 0) {
      const pickupCoordinates = gig.pickup // Directly use lat/long object
      const dropOffCoordinates = dropoffDetails.map((dropOff) => dropOff.address) // Keep as objects

      // Calculate distance (using Haversine formula, for example)
      const {totalDistance, perDropDistances} = await calculateDistance(pickupCoordinates, dropOffCoordinates)
      console.log('🚀 ~ useEffect ~ totalDistance:', totalDistance)
      console.log('🚀 ~ useEffect ~ perDropDistances:', perDropDistances)

      // Check isReturnToPickup condition and adjust the dropOffCoordinates count
      const adjustedDropOffCount = isReturnToPickup ? dropOffCoordinates.length - 1 : dropOffCoordinates.length

      // // Adjust drop-off details with perDropKm
      // if (perDropDistances && perDropDistances.length === dropoffDetails.length) {
      //   // Correctly update dropOffDetails with perDropKm
      //   const adjustedDropoffs = dropoffDetails.map((dropOff, index) => ({
      //     ...dropOff,
      //     perDropKm: perDropDistances[index] || 0 // Ensure a fallback value
      //   }))

      //   // Update dropoffDetails with the adjusted values
      //   setDropoffDetails(adjustedDropoffs)
      // } else {
      //   console.error('Mismatch in distances count')
      // }
      // Recalculate expected payment
      const perKMFee = parseFloat(ridersFee.gigRatePerKm) * totalDistance
      const addStopFee = 30 * adjustedDropOffCount
      const allowance = parseFloat(ridersFee.allowance)
      const expectedPayment = parseFloat(ridersFee.baseFare) + allowance + perKMFee + addStopFee + totalWaitTime

      const newRidersFee = {
        baseFare: ridersFee.baseFare,
        gigRatePerKm: ridersFee.gigRatePerKm,
        perKmFee: perKMFee,
        addPerDrop: addStopFee,
        expectedPayment: expectedPayment,
        totalKm: totalDistance,
        allowance: allowance,
        totalWaitingTimePay: totalWaitTime
      }

      setUpdatedRidersFee(newRidersFee)
      setExpectedGigFee(expectedPayment)
    }
  }, [dropoffDetails, gig.pickup, totalWaitTime])
  const calculateDistance = async (pickup, dropOffs) => {
    if (!pickup || !dropOffs.length) return 0

    const apiKey = process.env.GATSBY_OPENROUTE_API_KEY

    try {
      // Construct the coordinates array: [[pickupLongitude, pickupLatitude], [dropOffLongitude, dropOffLatitude], ...]
      const coordinates = [
        [pickup.long, pickup.lat], // Pickup location
        ...dropOffs.map((dropOff) => [dropOff.long, dropOff.lat]) // Each drop-off location
      ]

      // Make the API request to OpenRouteService
      const response = await axios.post(
        'https://api.openrouteservice.org/v2/directions/driving-car',
        {
          coordinates: coordinates
        },
        {
          headers: {
            Authorization: apiKey,
            'Content-Type': 'application/json'
          }
        }
      )

      const route = response.data.routes[0]
      const totalDistance = route.summary.distance / 1000 // Total distance in kilometers

      // Calculate per-drop distances
      const perDropDistances = route.segments.map((segment) => segment.distance / 1000)
      console.log('🚀 ~ calculateDistance ~ perDropDistances:', perDropDistances)

      return {totalDistance, perDropDistances}
    } catch (error) {
      console.error('Error fetching distance data:', error)
      return 0
    }
  }
  const handleAddPoint = (isPickup) => {
    setDropoffDetails([
      ...dropoffDetails,
      {timeArrived: '', timeFinnished: '', totalTime: '', address: {label: '', value: ''}, timeStamp: ''}
    ])
  }

  const handleRemovePoint = (isPickup, index) => {
    if (isPickup) {
      const updatedPickupDetails = pickupDetails.filter((_, i) => i !== index)
      setPickupDetails(updatedPickupDetails)
    } else {
      // Calculate waiting time of the dropoff being removed if it has a totalTime
      const removedDropoff = dropoffDetails[index]
      let updatedTotalWaitingTime = totalWaitTime

      if (removedDropoff?.totalTime) {
        const removedTotalMinutes = getTotalMinutes(removedDropoff.totalTime)
        const removedAdditionalAmount = Math.floor(removedTotalMinutes / 30) * 60
        updatedTotalWaitingTime -= removedAdditionalAmount
      }

      // Update the dropoff details
      const updatedDropoffDetails = dropoffDetails.filter((_, i) => i !== index)
      setDropoffDetails(updatedDropoffDetails)

      // Update the total wait time with the new calculated value
      setTotalWaitTime(updatedTotalWaitingTime)
    }
  }

  const handleOpenDialog = (isPickup, index) => {
    console.log(dropoffDetails[index], 'dropdetails')
    console.log(dropoffDetails, 'drop')
    console.log(gig.dropoffList, 'dropofflist')

    setIsEditingPickup(isPickup)
    setEditIndex(index)
    setOpenDialog(true)

    // If editing existing entry, pre-fill details
    if (isPickup) {
      const pickup = pickupDetails
      setAddress(pickup.address || '')
      setTimeArrived(pickup.timeArrived || '')
      setTimeFinnished(pickup.timeFinnished || '')
      setTotalTime(pickup.totalTime || '')
      setTimeStamp(pickup.timeStamp || '')
    } else if (!isPickup && dropoffDetails[index]) {
      const dropoff = dropoffDetails[index]
      setAddress(dropoff.address || '')
      setTimeArrived(dropoff.timeArrived || '')
      setTimeFinnished(dropoff.timeFinnished || '')
      setTotalTime(dropoff.totalTime || '')
      setTimeStamp(dropoff.timeStamp || '')
    } else if (!isPickup && !dropoffDetails[index]) {
      initializedDropoffs()
      return
    }
  }

  const handleCloseDialogRider = () => {
    setOpenDialog(false)
    clearForm()
  }
  const clearForm = () => {
    setAddress('')
    setTimeArrived('')
    setTimeFinnished('')
    setTotalTime('')
  }

  // Helper function to convert "HH:MM" to total minutes
  const getTotalMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number)
    return hours * 60 + minutes
  }

  const handleSaveDialog = async () => {
    let updatedTotalWaitingTime = totalWaitTime
    console.log('🚀 ~ handleSaveDialog ~ updatedTotalWaitingTime:', updatedTotalWaitingTime)

    // Validate the file type
    const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
    if (timeStamp && !validFileTypes.includes(timeStamp.type)) {
      enqueueSnackbar('Invalid file type. Only JPG, PNG, and PDF are allowed.', {
        variant: 'error'
      })
      return
    }

    // Validate required fields (timeArrived, timeFinished, address, timeStamp)
    if (!timeArrived || !timeFinnished || !address || !timeStamp) {
      enqueueSnackbar(
        'Please fill in all required fields: Time Arrived, Time Finished, Address, and Upload Time Stamp.',
        {
          variant: 'error'
        }
      )
      return
    }

    // Validate that timeArrived is before timeFinnished
    const arrivedTime = new Date(`1970-01-01T${timeArrived}:00`)
    const finishedTime = new Date(`1970-01-01T${timeFinnished}:00`)
    if (arrivedTime >= finishedTime) {
      enqueueSnackbar('Time Finished should be after Time Arrived.', {
        variant: 'error'
      })
      return
    }
    if (isEditingPickup) {
      // Handle waiting time for pickup
      if (pickupDetails?.totalTime) {
        // Subtract existing waiting time if present
        const existingTotalMinutes = getTotalMinutes(pickupDetails.totalTime)
        const existingAdditionalAmount = Math.floor(existingTotalMinutes / 30) * 60
        updatedTotalWaitingTime -= existingAdditionalAmount
      }

      // Add new waiting time for pickup
      if (totalTime) {
        const totalMinutes = getTotalMinutes(totalTime)
        const additionalAmount = Math.floor(totalMinutes / 30) * 60
        updatedTotalWaitingTime += additionalAmount
      }

      // Update the pickup details directly
      setPickupDetails({
        timeArrived: timeArrived,
        timeFinnished: timeFinnished,
        totalTime: totalTime,
        address: address,
        timeStamp: timeStamp
      })
    } else {
      // Handle waiting time for dropoff
      // if (dropoffDetails[editIndex].address) {
      //   return enqueueSnackbar('Drop Off address unavailable.', {
      //     variant: 'error'
      //   })
      // }
      console.log(dropoffDetails[editIndex].address, ' drop index details')
      const hasExistingWaitingTime = editIndex !== null && dropoffDetails[editIndex]?.totalTime
      console.log('🚀 ~ handleSaveDialog ~ hasExistingWaitingTime:', hasExistingWaitingTime)

      if (hasExistingWaitingTime) {
        // Subtract existing waiting time
        const existingTotalMinutes = getTotalMinutes(dropoffDetails[editIndex].totalTime)
        const existingAdditionalAmount = Math.floor(existingTotalMinutes / 30) * 60
        updatedTotalWaitingTime -= existingAdditionalAmount
      }

      // Add new waiting time for dropoff
      if (totalTime) {
        const totalMinutes = getTotalMinutes(totalTime)
        const additionalAmount = Math.floor(totalMinutes / 30) * 60
        updatedTotalWaitingTime += additionalAmount
      }

      // Update the dropoff details
      let newDetails = [...dropoffDetails]
      if (editIndex !== null) {
        newDetails[editIndex] = {
          timeArrived: timeArrived,
          timeFinnished: timeFinnished,
          totalTime: totalTime,
          address: address,
          timeStamp: timeStamp
        }
      } else {
        newDetails.push({
          timeArrived: timeArrived,
          timeFinnished: timeFinnished,
          totalTime: totalTime,
          address: address,
          timeStamp: timeStamp
        })
      }

      if (gig.pickup && newDetails.length > 0) {
        const pickupCoordinates = gig.pickup // Use the pickup coordinates
        console.log('🚀 ~ handleSaveDialog ~ pickupCoordinates:', pickupCoordinates)
        const dropOffCoordinates = newDetails.map((dropOff) => dropOff.address) // Get drop-off addresses
        console.log('🚀 ~ handleSaveDialog ~ dropOffCoordinates:', dropOffCoordinates)

        // Calculate distance (using Haversine formula, for example)
        const {perDropDistances} = await calculateDistance(pickupCoordinates, dropOffCoordinates)
        console.log('🚀 ~ handleSaveDialog ~ perDropDistances:', perDropDistances)

        // Update perDropKm for each drop-off
        newDetails = newDetails.map((dropOff, index) => ({
          ...dropOff,
          perDropKm: perDropDistances[index] || 0 // Set perDropKm for each dropoff
        }))
      }
      setDropoffDetails(newDetails)
    }

    // Update the total wait time
    setTotalWaitTime(updatedTotalWaitingTime)

    // Close the dialog after saving
    handleCloseDialogRider()
  }

  const initializedDropoffs = () => {
    if (arrBranches.length > 0) {
      // For Dropoffs
      if (gig?.dropoffList && gig?.dropoffList.length > 0) {
        const initializedDropoffs = gig?.dropoffList.map((dropOff) => {
          let matchedBranch = arrBranches.find(
            (branch) => branch.label === dropOff.address || branch.value === dropOff.address
          )

          // If no branch is found, check arrPickups
          if (!matchedBranch) {
            matchedBranch = arrPickups.find(
              (pickup) => pickup.label === dropOff.address || pickup.value === dropOff.address
            )
          }

          return {
            timeArrived: dropOff.timeFinnished ? dropOff.timeArrived : '',
            timeFinnished: dropOff.timeFinnished || '',
            totalTime: dropOff.timeFinnished || '',
            address: matchedBranch || {label: '', addressInfo: ''}, // Replace with the matched branch or a default
            timeStamp: ''
          }
        })
        console.log('🚀 ~ initializedDropoffs ~ initializedDropoffs:', initializedDropoffs)

        setDropoffDetails(initializedDropoffs)
      }

      // For Pickup
      if (gig?.pickup) {
        const matchedPickupBranch = arrPickups.find(
          (index) => index.label === gig.pickup?.address || index.value === gig.pickup.address
        )

        const initializedPickup = {
          timeArrived: gig.pickup.timeFinnished ? gig.pickup.timeArrived : '',
          timeFinnished: gig.pickup.timeFinnished || '',
          totalTime: gig.pickup.timeFinnished || '',
          address: matchedPickupBranch || {label: '', addressInfo: ''},
          timeStamp: ''
        }

        setPickupDetails(initializedPickup)
      }

      localStorage.getItem('payment') && setPaymentOptions(JSON.parse(localStorage.getItem('payment')))
    }
  }

  useEffect(() => {
    initializedDropoffs()
  }, [gig, arrBranches, arrPickups, gig.dropoffList])

  const hoursLimit = () => {
    if (fees) {
      return gig.hours < 8 ? 9 : parseInt(gig.hours) + 1
    }
    // return 24
    return 9
  }

  const handleCommit = async (values) => {
    setScrnLoading(true)
    setChecked(false)
    //
    let uploadedFiles = {}
    let hasInvalidFile = false

    // Helper function for handling file uploads
    const uploadFile = async (file) => {
      const fileType = file.type
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/jpg' ||
        fileType === 'image/png' ||
        fileType === 'application/pdf'
      ) {
        const uploadedFileName = await onboard_api.request_upload_url(file)
        if (uploadedFileName) {
          return uploadedFileName
        } else {
          console.error(`Failed to upload file: ${file.name}`)
        }
      } else {
        hasInvalidFile = true
        enqueueSnackbar('Invalid file type. Only JPG, PNG, and PDF are allowed.', {
          variant: 'error'
        })
      }
      return null
    }

    // Upload files for pickup details
    if (pickupDetails && pickupDetails.timeStamp) {
      const uploadedPickupFile = await uploadFile(pickupDetails.timeStamp)
      if (uploadedPickupFile) {
        uploadedFiles.pickupTimeStamp = uploadedPickupFile
      }
    }

    // Upload files for dropoff details
    if (Array.isArray(dropoffDetails)) {
      const uploadPromises = dropoffDetails.map(async (dropoff, index) => {
        if (dropoff.timeStamp) {
          const uploadedDropoffFile = await uploadFile(dropoff.timeStamp)
          if (uploadedDropoffFile) {
            uploadedFiles[`dropoff_${index}_timeStamp`] = uploadedDropoffFile
          }
        }
      })

      await Promise.all(uploadPromises)
    }

    if (deliveryProof && deliveryProof.length > 0) {
      const deliveryProofPromises = Array.from(deliveryProof).map(async (file) => {
        return await uploadFile(file)
      })

      const uploadedDeliveryFiles = await Promise.all(deliveryProofPromises)

      // Store the uploaded files in an array inside `uploadedFiles`
      uploadedFiles.deliveryProof = uploadedDeliveryFiles.filter(Boolean)
    }

    if (hasInvalidFile) {
      setLoading(false)
      return
    }

    try {
      let data = {
        new_status: 'End-Shift',
        dropoffDetails: dropoffDetails ?? [],
        pickupDetails: pickupDetails ?? [],
        deliveryProof: deliveryProof ?? [],
        uploadedFiles,
        updatedRidersFee: updatedRidersFee ?? {},
        jobsterNotes: jobsterNotes ?? '',
        paymentDetails: paymentDetails ?? {},
        ...values
      }

      console.log(data, 'DATA')
      // uncomment onCommit for testing
      onCommit(data)
    } catch (error) {
      console.log(error)
    } finally {
      console.log('Finallllly')
      setScrnLoading(false) // Hide loading screen
    }
  }

  const formatDecimal = (value, type) => {
    if (!value) return
    if (type === 'hours') {
      setWorkTime(parseFloat(value).toFixed(2))
    }
    if (type === 'late') {
      setLate(parseFloat(value).toFixed(2))
    }
    if (type === 'extension') {
      setExtension(parseFloat(value).toFixed(2))
    }
    if (type === 'nightSurge') {
      setNightSurge(parseFloat(value).toFixed(2))
    }
  }

  const handleTime = (value) => {
    const absoluteHours = Math.floor(Math.abs(value))
    const minutes = Math.floor((Math.abs(value) * 60) % 60)

    return (
      <>
        {' about your new worktime '}
        <br></br>
        <b style={{color: '#0d7bc9'}}>{`${absoluteHours} hours ${minutes} mins`}</b>
        <br></br>
      </>
    )
  }

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  // Dialog variables
  const [expectedGigFee, setExpectedGigFee] = useState('')
  const [isNotComplete, setIsNotComplete] = useState(true)

  const parseTime = (time) => {
    const hours = Math.floor(time)
    const minutes = Math.round((time - hours) * 60)
    return {hours, minutes}
  }

  const isEmpty = () => {
    // Validate pickupDetails
    const isPickupEmpty =
      pickupDetails.address === '' ||
      pickupDetails.timeArrived === '' ||
      pickupDetails.timeFinnished === '' ||
      pickupDetails.totalTime === '' ||
      pickupDetails.timeStamp === ''

    // Validate dropoffDetails (array)
    const isDropoffEmpty = dropoffDetails.some(
      (detail) =>
        detail.timeArrived === '' ||
        detail.timeFinnished === '' ||
        detail.totalTime === '' ||
        detail.address.label === '' ||
        detail.timeStamp === ''
    )

    const isDeliveryProofEmpty = deliveryProof.length === 0

    const isPaymentEmpty = !paymentDetails || !paymentDetails.value

    return isPickupEmpty || isDropoffEmpty || isDeliveryProofEmpty || isPaymentEmpty
  }

  const handleHoursChange = (event, setter) => {
    setter(event.target.value)
  }

  const handleMinutesChange = (event, setter) => {
    setter(event.target.value)
  }
  useEffect(() => {
    if (gig) {
      const {ridersFee} = gig
      console.log('🚀 ~ useEffect ~ ridersFee:', ridersFee)
      setLimit(hoursLimit())

      if (!isEmpty()) {
        setIsNotComplete(false)
      }

      //   const {jobsterFinal} = endShift_calculations(
      //     calculatedWorktime,
      //     gig?.fee,
      //     fees?.voluntaryFee,
      //     fees?.premiumFee,
      //     fees?.holidaySurge,
      //     convertedLate,
      //     calculatedExtension,
      //     calculatedNightSurge
      //   )
      // let expPay = parseFloat(ridersFee?.expectedPayment) || 0
      // setExpectedGigFee(expPay.toFixed(2))
    }
  }, [dropoffDetails, pickupDetails, address, timeArrived, timeFinnished, totalTime, deliveryProof, paymentDetails])

  const calculateTotalTime = (timeArrived, timeFinnished) => {
    if (!timeArrived || !timeFinnished) return ''

    const [arrivedHours, arrivedMinutes] = timeArrived.split(':').map(Number)
    const [finishedHours, finishedMinutes] = timeFinnished.split(':').map(Number)

    // Create Date objects for both times
    const arrivedTime = new Date()
    const finishedTime = new Date()

    arrivedTime.setHours(arrivedHours, arrivedMinutes)
    finishedTime.setHours(finishedHours, finishedMinutes)

    // Calculate the difference in milliseconds
    const difference = finishedTime - arrivedTime

    // Convert the difference to hours and minutes
    const totalMinutes = Math.floor(difference / (1000 * 60))
    const totalHours = Math.floor(totalMinutes / 60)
    const remainingMinutes = totalMinutes % 60

    // Format the result as "HH:MM"
    const formattedTotalTime = `${String(totalHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`

    return formattedTotalTime
  }

  const formatBranches = () => {
    let arrBranches = []
    branches.map((item) => {
      // arrArea.push({value: item, label: capitalCase(item)})
      arrBranches.push({
        value: item.name,
        label: capitalCase(item.name),
        route: item.route,
        lat: item.lat,
        long: item.long,
        location: item.location
      })
    })

    // pickups
    pickups.map((item) => {
      arrPickups.push({
        value: item.name,
        label: capitalCase(item.name),
        lat: item.lat,
        long: item.long,
        location: item.location
      })
    })

    setBranches(arrBranches)
    setPickups(arrPickups)
  }

  useEffect(() => {
    formatBranches()
  }, [branches, pickups])

  const handleCloseDialog = () => {
    handleClose()
    setIsNotComplete(true)
    formatBranches()
    setIsReturnToPickup(false)
    setTotalWaitTime(0)
    setTimeStamp('')
    setDeliveryProof([])
  }
  // Inside your component
  useEffect(() => {
    const total = calculateTotalTime(timeArrived, timeFinnished)
    console.log('🚀 ~ useEffect ~ total:', total)
    setTotalTime(total)
  }, [timeArrived, timeFinnished])

  useEffect(() => {
    const formatDropBranches = () => {
      let dropBranches = []

      if (dropoffList) {
        dropoffList.map((item) => {
          // arrArea.push({value: item, label: capitalCase(item)})
          dropBranches.push({
            value: item.address,
            label: capitalCase(item.address),
            route: item.route,
            lat: item.lat,
            long: item.long
            // location: item.location
          })
        })
      }
      setDropBranches(dropBranches)
    }
    formatDropBranches()
  }, [dropoffList])

  return (
    <>
      {isScrnLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseDialog()
          }
        }}
        sx={{'& .MuiDialog-paper': {minWidth: '300px', maxWidth: '900px', width: '80%'}}}
      >
        <Button sx={{ml: 'auto', pt: 2}} onClick={handleCloseDialog}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" component="span">
            Confirm End-Shift
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Box border={1} borderColor="primary.main" sx={{p: 2, mb: 2, borderRadius: 1}}>
            <Box sx={{mb: 2, display: 'flex', alignItems: 'center'}}>
              <RadioButtonUncheckedIcon color="warning" sx={{mr: 1}} />
              {pickupDetails.address ? (
                <Box onClick={() => handleOpenDialog(true)} sx={{cursor: 'pointer'}}>
                  <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                    {gig.pickup?.address}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Lat: {gig.pickup?.lat}, Lng: {gig.pickup?.long}
                  </Typography>
                </Box>
              ) : (
                <Button sx={{color: 'text.secondary'}} onClick={() => handleOpenDialog(true)}>
                  Enter pickup details
                </Button>
              )}
            </Box>
            <Divider style={{width: '100%', marginBottom: 20}} />
            {/* Dropoff Details */}
            {dropoffDetails.map((detail, index) => (
              <Box key={index} sx={{mb: 2, display: 'flex', alignItems: 'center'}}>
                <LocationOnIcon color="primary" sx={{mr: 1}} />
                {detail.address.label ? (
                  <Box onClick={() => handleOpenDialog(false, index)} sx={{cursor: 'pointer'}}>
                    <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>
                      {detail.address.label}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Lat: {detail.address.lat}, Lng: {detail.address.long}
                    </Typography>
                    {detail.perDropKm && (
                      <Typography variant="body2" color="textSecondary">
                        KM Per Drop: {detail.perDropKm}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Button sx={{color: 'text.secondary'}} onClick={() => handleOpenDialog(false, index)}>
                    Enter drop-off details
                  </Button>
                )}
                {detail.address.label !== pickupDetails.address?.value && (
                  <IconButton color="error" sx={{ml: 1}} onClick={() => handleRemovePoint(false, index)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            {console.log(dropoffDetails, 'drops')}
            {/* Add Another Point */}
            <Box sx={{mb: 2, display: 'flex', alignItems: 'center'}}>
              <IconButton color="primary" sx={{mb: 1, mt: 1, pl: '0 !important'}} onClick={handleAddPoint}>
                <AddCircleOutlineIcon color="primary" sx={{mr: 1}} />
                <Typography sx={{ml: 1, color: 'text.secondary'}}>Add another point</Typography>
              </IconButton>
            </Box>
            <Dialog
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleCloseDialog()
                }
              }}
            >
              <DialogTitle>
                <Typography variant="h6" textAlign="center" sx={{mt: 2}}>
                  {isEditingPickup ? 'Enter Pick Up Details' : 'Enter Drop Off Details'}
                </Typography>
              </DialogTitle>
              <DialogContent sx={{mt: 2, mb: 2, mr: 2}}>
                {/* <TextField
                  label="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  disabled
                  fullWidth
                  sx={{mb: 2, mt: 1}}
                /> */}
                <Autocomplete
                  options={editIndex > gig.dropOffs?.length - 1 ? arrBranches : dropBranches}
                  getOptionLabel={(option) => option.label}
                  value={address}
                  disabled={isEditingPickup}
                  onChange={(event, newValue) => setAddress(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Drop-off Location" variant="outlined" />
                  )}
                  sx={{mb: 2, mt: 1}}
                  PaperComponent={(props) => <Paper {...props} sx={{zIndex: 1300}} />}
                />
                <TextField
                  label="Time Arrived"
                  type="time"
                  value={timeArrived}
                  onChange={(e) => setTimeArrived(e.target.value)}
                  fullWidth
                  sx={{mb: 2}}
                  InputLabelProps={{
                    shrink: true // Ensure the label stays above the input when a value is selected
                  }}
                />

                <TextField
                  label="Time Finished"
                  type="time"
                  value={timeFinnished}
                  onChange={(e) => setTimeFinnished(e.target.value)}
                  fullWidth
                  sx={{mb: 2}}
                  InputLabelProps={{
                    shrink: true
                  }}
                />

                <TextField
                  label="Total Waiting Time"
                  type="text" // Change to 'text' since it's a calculated string
                  value={totalTime}
                  fullWidth
                  sx={{mb: 2}}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled // Make it read-only since it's calculated automatically
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} sx={{pl: '0 !important'}}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="barangay-clearance"
                        sx={{
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          fontSize: 14,
                          fontWeight: 'bold',
                          paddingBottom: '8px'
                        }}
                      >
                        Upload Time Stamp<span style={{color: 'red'}}> *</span>
                      </InputLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Input
                      id="time-stamp"
                      type="file"
                      name="timeStamp"
                      required
                      inputProps={{accept: 'image/*, .pdf'}}
                      onChange={(event) => setTimeStamp(event.currentTarget.files[0])}
                    />
                  </Grid>

                  {/* Display the uploaded file name */}
                  {timeStamp instanceof Blob && (
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2">
                        Uploaded File:
                        <a href={URL.createObjectURL(timeStamp)} target="_blank" rel="noopener noreferrer">
                          {timeStamp.name || 'View File'}
                        </a>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialogRider}>Cancel</Button>
                <Button onClick={handleSaveDialog}>Save</Button>
              </DialogActions>
            </Dialog>
          </Box>
          {/* Return to Pickup Checkbox */}
          <FormControlLabel
            control={<Checkbox checked={isReturnToPickup} onChange={handleReturnToPickup} color="primary" />}
            label="Return to Pickup"
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} sx={{pl: '0 !important'}}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="delivery-proof"
                  sx={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontSize: 14,
                    fontWeight: 'bold',
                    paddingBottom: '8px'
                  }}
                >
                  Upload delivery proof<span style={{color: 'red'}}> *</span>
                </InputLabel>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Input
                id="delivery-proof"
                type="file"
                name="deliveryProof"
                required
                inputProps={{accept: 'image/*, .pdf', multiple: true}}
                onChange={(event) => setDeliveryProof(event.currentTarget.files)}
              />
            </Grid>

            {/* Show uploaded files if available */}
            {deliveryProof && deliveryProof.length > 0 && (
              <Grid item xs={12} sm={12}>
                <Typography variant="body2">Uploaded Files:</Typography>
                <ul>
                  {Array.from(deliveryProof).map((file, index) => (
                    <li key={index}>
                      <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>
          {/* <Divider sx={{width: '100%', mt: 2, mb: 2, ml: 10}} /> */}

          <Accordion sx={{mt: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
              <Typography sx={{fontWeight: 'bold'}}>Expected Gig Fee: &nbsp; </Typography>
              <Typography sx={{color: 'starjobs.main', fontWeight: 'bold'}}>
                Php {expectedGigFee ? expectedGigFee.toFixed(2) : parseFloat(ridersFee?.expectedPayment).toFixed(2)}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container sx={{mt: 2}}>
                {/* Base Fare */}
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Base Fare:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>Php {parseFloat(ridersFee?.baseFare).toFixed(2)}</Typography>
                  </Grid>
                </Grid>

                {/* Gig Rate Per Km */}
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Gig Rate Per Km:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>
                      Php{' '}
                      {updatedRidersFee.gigRatePerKm
                        ? updatedRidersFee.gigRatePerKm
                        : parseFloat(ridersFee?.gigRatePerKm).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Total Kilometers */}
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Total Kilometers:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>
                      {updatedRidersFee.totalKm
                        ? parseFloat(updatedRidersFee.totalKm).toFixed(2)
                        : parseFloat(ridersFee?.totalKm).toFixed(2)}{' '}
                      km
                    </Typography>
                  </Grid>
                </Grid>

                {/* Per Km Fee */}
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Total KM Fee:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>
                      Php{' '}
                      {updatedRidersFee.perKmFee
                        ? parseFloat(updatedRidersFee.perKmFee).toFixed(2)
                        : parseFloat(ridersFee?.perKmFee).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Additional Per Drop */}
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Additional Per Drop:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>
                      Php{' '}
                      {updatedRidersFee.addPerDrop
                        ? updatedRidersFee.addPerDrop
                        : parseFloat(ridersFee?.addPerDrop).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Total Waiting Time Pay:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>Php {parseFloat(totalWaitTime).toFixed(2)}</Typography>
                  </Grid>
                </Grid>
                {/* Allowance */}
                <Grid item container alignItems="center">
                  <Grid item xs={6}>
                    <Typography>Allowance:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign: 'left'}}>
                    <Typography>Php {parseFloat(updatedRidersFee?.allowance).toFixed(2)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Grid item container spacing={1} alignItems="center" sx={{pl: 0}}>
            <Grid item xs={4} sx={{pl: '0 !important'}}>
              <Typography>Payment Details:</Typography>
            </Grid>
            {console.log('paaay', paymentDetails)}
            <Grid item xs={7} sx={{pl: 0}}>
              <FormControl fullWidth>
                <InputLabel>
                  {paymentDetails === paymentOptions?.acccountPaymentNumber
                    ? 'Gcash Primary'
                    : paymentDetails === paymentOptions?.altacctPaymentNumber
                    ? 'Gcash Secondary'
                    : 'Select Gcash Details'}
                </InputLabel>
                <Select
                  value={paymentDetails.value || ''} // Ensure it's not undefined
                  label={
                    paymentDetails === paymentOptions?.acccountPaymentNumber
                      ? 'Gcash Primary'
                      : paymentDetails === paymentOptions?.altacctPaymentNumber
                      ? 'Gcash Secondary'
                      : 'Select Gcash Details'
                  }
                  renderValue={(selected) =>
                    selected
                      ? `****${selected.slice(-4)}` // Show only last 4 digits when selected
                      : 'Select Gcash Details'
                  }
                  onChange={(e) => handlePaymentDetails(e)}
                >
                  {/* {paymentOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))} */}
                  <MenuItem
                    key={1}
                    name={paymentOptions?.acccountPaymentName}
                    value={paymentOptions?.acccountPaymentNumber || ''}
                  >
                    Primary{' '}
                    {paymentOptions?.acccountPaymentNumber
                      ? `****${paymentOptions?.acccountPaymentNumber.slice(-4)}`
                      : 'N/A'}
                  </MenuItem>
                  {paymentOptions?.altAcctPaymentNumber && (
                    <MenuItem
                      key={2}
                      name={paymentOptions?.altAcctPaymentName}
                      value={paymentOptions.altAcctPaymentNumber}
                    >
                      Secondary {`****${paymentOptions?.altAcctPaymentNumber.slice(-4)}`}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TextField
            id="Notes"
            label="Notes (Optional)"
            key="Notes"
            name="Notes"
            value={jobsterNotes}
            onChange={(e) => setJobsterNotes(e.target.value)}
            rows={3}
            type="text"
            fullWidth
            multiline
            sx={{mb: 3, mt: 2}}
          />

          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => setIsFinal(true)}
              loading={loading}
              disabled={isNotComplete}
            >
              End Shift
            </LoadingButton>
            <LoadingButton onClick={handleCloseDialog} size="large" variant="outlined" color="inherit" sx={{mt: 2}}>
              No
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Final confirm dialog */}
      {/* <Dialog open={isFinal} onClick={() => setIsFinal(false)}> */}
      <Dialog open={isFinal}>
        <Button
          sx={{ml: 'auto', pt: 2}}
          onClick={() => {
            setIsFinal(false)
            setChecked(false)
          }}
        >
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Do you confirm to end this Gig?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="h6" sx={{mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto'}}>
            Please ensure all tasks are completed accurately and efficiently. Ensure proper endorsement and turnover
            before confirming the successful completion of the gig.
            <strong> Confirm only if everything is in order.</strong>
            <Divider sx={{mb: 1, mt: 2, fontWeight: 400, backgroundColor: 'rgba(0, 0, 0, 0.87)'}} />
            Once confirmed,<strong>time records cannot be adjusted and gig fees will be released.</strong> Please ensure
            that all details are correct before confirming.
          </Typography>
          <Box
            sx={{
              display: 'inline',
              width: {xs: '50%', sm: '33%', lg: '33%'},
              opacity: loading ? 0.5 : 1, // Reduce opacity when loading
              cursor: loading ? 'not-allowed' : 'default' // Change cursor during loading
            }}
          >
            <FormControlLabel
              sx={{marginTop: '0 !important'}}
              control={
                <Checkbox
                  id="skills"
                  color="primary"
                  checked={checked}
                  onChange={(e) => handleChange(e)}
                  disabled={loading}
                  // value={checked}
                />
              }
              label={
                <Typography variant="body2" align="left" sx={{color: 'text.secondary', marginTop: '0 !important'}}>
                  I confirm that the time records are correct
                </Typography>
              }
            />
          </Box>
          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => handleCommit(gig)}
              loading={loading}
              disabled={!checked}
              sx={{textTransform: 'initial !important'}}
            >
              Yes, I have informed the client
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                setIsFinal(false)
                setChecked(false)
              }}
              size="large"
              variant="outlined"
              color="inherit"
              sx={{mt: 2}}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

EndShiftNotificationRider.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  gig: PropTypes.array,
  onCommit: PropTypes.func,
  handleClose: PropTypes.func,
  onReject: PropTypes.func,
  loading: PropTypes.bool,
  branches: PropTypes.array,
  pickups: PropTypes.array
}

export default EndShiftNotificationRider
